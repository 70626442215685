import { Container, hover, Img, passed, Row, tabHeader } from 'Design';
import React, { useState } from 'react';
import styled from 'styled-components';
import logo from 'images/logo.png';
import menu from 'images/menu.png';
import { BREAKPOINTS } from 'utils';
import { Menu } from 'components/Menu';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <HeaderContainer>
      <StyledContainer
        paddingX={48}
        paddingY={16}
        xlPaddingX={32}
        xlPaddingY={12}
        mdPaddingX={16}
        mdPaddingY={10}
      >
        <Row spaceBetween alignCenter>
          <Link to='/'>
            <Logo src={logo} />
          </Link>
          <NavContainer mdHidden>
            <StyledRow>
              <StyledLink to='/about-us'>О нас</StyledLink>
              <StyledLink to='/news'>Новости</StyledLink>
              <StyledLink to='/purchases'>Закупки</StyledLink>
              <StyledLink to='/jobs'>Вакансии</StyledLink>
              {/* <StyledLink to='/team'>Команда</StyledLink> */}
              <StyledLink to='/contacts'>Контакты</StyledLink>
            </StyledRow>
          </NavContainer>
          <Container xlHidden>
            <PhoneNumber>+7 495 899-09-08</PhoneNumber>
          </Container>
          <Container hidden mdVisible onClick={() => setMenuOpened(true)}>
            <Burger src={menu} alt={'burger-menu'} />
          </Container>
        </Row>
      </StyledContainer>
      <Menu opened={menuOpened} onClose={() => setMenuOpened(false)} />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const StyledContainer = styled(Container)`
  margin: 0 auto;
  max-width: 1276px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 20px 20px;
`;

const Logo = styled(Img)`
  width: 108px;
  @media (max-width: ${BREAKPOINTS.xl}) {
    width: 120px;
  }
  @media (max-width: ${BREAKPOINTS.md}) {
    width: 100px;
  }
`;

const NavContainer = styled(Container)``;

const StyledLink = styled(Link)`
  ${tabHeader}
  cursor: pointer;
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
`;

const PhoneNumber = styled.a`
  ${tabHeader}
  cursor: pointer;
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
`;

const Burger = styled(Img)`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  gap: 40px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    gap: 25px;
  }
`;
